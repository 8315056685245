import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import SubCategory from "./components/cards/subcategory";
import Category from "./components/category/category";
import SingleProduct from "./components/products/singleProduct";
import { Provider } from "react-redux";
import store from "./store/store";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/header/header";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={App} />
          <Route path="/sub-category/:id" component={SubCategory} />
          <Route path="/category/:id" component={Category} />
          <Route path="/product/:id" component={SingleProduct} />
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
